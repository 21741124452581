@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-Bold.woff2) format("woff2"), url(/public/fonts/GoodSans/GoodSans-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-BoldItalic.woff2) format("woff2"),
    url(/public/fonts/GoodSans/GoodSans-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-Medium.woff2) format("woff2"), url(/public/fonts/GoodSans/GoodSans-Medium.woff) format("woff");
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-MediumItalic.woff2) format("woff2"),
    url(/public/fonts/GoodSans/GoodSans-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-Regular.woff2) format("woff2"), url(/public/fonts/GoodSans/GoodSans-Regular.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-family: "Good Sans";
  src: url(/public/fonts/GoodSans/GoodSans-RegularItalic.woff2) format("woff2"),
    url(/public/fonts/GoodSans/GoodSans-RegularItalic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
