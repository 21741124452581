.MainLayout.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.MainLayout > main.white {
  background-color: #ffffff;
}

.MainLayout > main.blue {
  background-color: var(--bg-color);
}

.MainLayout.wrapper .HeaderBlock {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../../build/images/flowers-4.webp");
  background-size: cover;
  background-position: 50% 50%;
}
