.PortfolioSelector .buttons .ui.button {
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.PortfolioSelector .ui.button:hover,
.PortfolioSelector .ui.button:active {
  background-color: var(--white-hov-color);
}

.PortfolioSelector .ui.button.active {
  background-color: var(--secondary-color);
  color: #fff;
}

.PortfolioSelector .ui.button.active:hover,
.PortfolioSelector .ui.button.active:active {
  background-color: var(--primary-color);
  color: #fff;
}

.PortfolioSelector .primary-portfolio .ui.button.active {
  background-color: var(--primary-color);
}

.PortfolioSelector .primary-portfolio .ui.button.active:hover,
.PortfolioSelector .primary-portfolio .ui.button.active:active {
  background-color: var(--primary-hov-color);
  color: #fff;
}

.PortfolioSelector .risk-gradient {
  width: 83.33%; /* 5/6 - 5 portfolio buttons, 1 "cust" button */
  height: 5px;
  background: linear-gradient(90deg, var(--carnegie-light-orange) 0%, var(--carnegie-orange) 50%, var(--carnegie-red) 100%);
}

.PortfolioSelector .risk-labels {
  width: 83.33%;
  display: flex;
  margin-top: 5px;

  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
}

.PortfolioSelector .risk-labels .less-risk {
  flex-grow: 1;
  text-align: left;
}

.PortfolioSelector .risk-labels .more-risk {
  flex-grow: 1;
  text-align: right;
}
