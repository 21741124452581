.SimulationNameRibbon {
  font-size: 16px;
  font-style: italic;
  margin-right: 20px;
  min-height: 33px;
  float: right;
}

.SimulationNameRibbon .unsaved {
  margin-right: 20px;
}

.SimulationNameRibbon .ui.button.save-btn {
  width: 110px;
  margin-right: 0;
}

.ProcessNavPageHeader > .SimulationNameRibbon {
  margin-right: 0;
}

.SimulationNameRibbon .ui.label.saving-status {
  font-style: normal;
  margin-left: 20px;
  font-size: 12px;
  opacity: 0.5;
  margin: 9px 10px;
}
