.MySavedSimulationsPage {
  background-color: var(--bg-color);
}

.MySavedSimulationsPage table.ui.table {
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 0;
}

.MySavedSimulationsPage .PDFButton {
  color: #fff !important;
}

.MySavedSimulationsPage .ui.button.negative-btn,
.MySavedSimulationsPage .ui.button.secondary-btn {
  margin-left: 5px;
}

.MySavedSimulationsPage .PDFButton .MuiSvgIcon-root {
  font-size: 20px;
}

.MySavedSimulationsPage .ui.button.secondary-btn .MuiSvgIcon-root {
  font-size: 20px;
}
