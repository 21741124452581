.ui.grid.JoinedForwardSimulationNumbersPane {
  margin: 25px 0 0 0;
  color: #4e4e4e;
}

.JoinedForwardSimulationNumbersPane .big-value {
  display: block;
  font-weight: bold;
  color: var(--primary-color);
  line-height: 1.25em;
}

.JoinedForwardSimulationNumbersPane .big-value {
  font-size: 2em;
}

.JoinedForwardSimulationNumbersPane .big-value.secondary {
  color: var(--secondary-color);
}

@media screen and (min-width: 768px) {
  .JoinedForwardSimulationNumbersPane .big-value {
    font-size: 2em;
  }
}
