.ProcessNavigator .stackable.grid {
  margin-top: 0;
  margin-bottom: 0;
}

.ProcessNavigator .ui.header {
  color: #f4f2f2;
}

.ProcessNavigator.unsaved .ui.header {
  font-style: italic;
  font-weight: normal;
  color: #f4f2f2a0;
}

.ProcessNavigator i.icon {
  text-decoration: none;
}

/* Process navigation header */

.ProcessNavPageHeader {
  background: #fff;
  background-size: cover;
  padding: 20px 0 20px;
}

.ProcessNavPageHeader .simulate-btn {
  width: 110px;
  float: right;
  margin-right: 0;
}

.ProcessNavPageHeader .ui.header,
.ProcessNavPageHeader .ui.header:first-child {
  order: 1;
  flex-grow: 1;
  margin: 0;
}

.ProcessNavPageHeader .ProcessNavigator {
  order: 2;
  flex-shrink: 1;
  /* margin-top: 5px; */
}

@media screen and (max-width: 1023px) {
  /* Mobiles and tablets */
  .ProcessNavPageHeader .container {
    flex-direction: column-reverse;
  }
}

/* Prev & Next buttons */

.ProcessNavigator .prev-btn.ui.button,
.ProcessNavigator .next-btn.ui.button,
.ProcessNavigator .simulate-btn.ui.button {
  /* Previous, next & simulate buttons */
  font-size: 13px;
}

.ProcessNavigator {
  margin-right: 0;
}

.ProcessNavigator .prev-btn.ui.labeled.icon.button {
  /* Previous button */
  background: var(--bg-color);
  color: #999;
}

.ProcessNavigator .prev-btn.ui.labeled.icon.button:hover {
  /* Previous button */
  background: #e4e4e4;
}

/* Steps list */
.ProcessNavigator ul.process-steps {
  display: inline-block;
  list-style-type: none;
  margin: 0 38px 0 38px;
  padding: 0;
}

.ProcessNavigator ul.process-steps > li {
  display: inline-block;
  vertical-align: top;
}

.ProcessNavigator ul.process-steps > li:last-child {
  margin-right: 0;
}

.ProcessNavigator ul.process-steps > li a {
  color: #999;
  font-size: 30px;
}

.ProcessNavigator ul.process-steps > li a i {
  vertical-align: top;
}

.ProcessNavigator ul.process-steps > li a span {
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.ProcessNavigator ul.process-steps > li.step-previous a,
.ProcessNavigator ul.process-steps > li.step-current a {
  color: var(--primary-color);
}

.ProcessNavigator .icon.arrow {
  margin-left: 3px;
  vertical-align: 80%;
  color: #999;
}

.ProcessNavigator .step-previous .icon.arrow {
  color: var(--primary-color);
}

.ProcessNavigator .stepicon {
  font-size: 30px;
}

.ProcessNavFooter {
  margin: 20px 0;
}
