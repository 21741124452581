.ForwardSimulationGraph h2.section-first {
  padding-top: 1px;
}

.ForwardSimulationGraph .ui.header {
  color: var(--font-color);
}

.ForwardSimulationGraph .ui.compact.square-corners.menu {
  border-radius: 0;
  border: 1px solid var(--primary-color);
}

.ForwardSimulationGraph .ui.compact.square-corners.menu .item:first-child,
.ForwardSimulationGraph .ui.compact.square-corners.menu > .item:first-child:first-child:hover {
  border-radius: 0;
}

.ForwardSimulationGraph .ui.compact.square-corners.menu .item:last-child,
.ForwardSimulationGraph .ui.compact.square-corners.menu > .item:first-child:last-child:hover {
  border-radius: 0;
}

.ForwardSimulationGraph .year__selector {
  background-color: white;
}

.ForwardSimulationGraph .year__selector:hover {
  background: var(--white-hov-color);
}

.ForwardSimulationGraph .ui.menu .active.item {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.ForwardSimulationGraph .ui.menu .item:hover {
  background-color: var(--bg-color) !important;
}

.ForwardSimulationGraph .ui.menu .active.item:hover {
  background-color: var(--primary-hov-color) !important;
}
