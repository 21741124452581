/* -- Custom portfolio modal -- */
.ui.modal > .content,
.ui.modal > .content {
  background-color: var(--bg-color) !important;
}

.CustomPortfolioModal .ui.input > input {
  border: none;
  font-weight: 600;
}

.CustomPortfolioModal .ui.form .inline.field label {
  font-weight: normal;
}

.CustomPortfolioModal .ui.statistic.total-sum > .value {
  font-size: 90px !important;
  font-weight: 600;
}

.CustomPortfolioModal .ui.form .inline.field > .ui.huge.input {
  width: 4em;
}

.CustomPortfolioModal .ui.form .field.index-fund > label {
  font-style: italic;
  color: #999 !important;
}

@media screen and (min-width: 768px) {
  .CustomPortfolioModal .ui.message.sum-warning {
    margin-bottom: 10px; /* To align visually with .total-sum */
  }

  .CustomPortfolioModal .ui.form .inline.field > .ui.huge.input {
    width: 5.2em;
  }
}
