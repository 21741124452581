@media all {
  .HeaderBlock svg * {
    color: var(--bg-color) !important;
  }
  header .col-header {
    width: 33.333333%;
  }

  .account-dropdown i.icon,
  .account-dropdown i.icons {
    font-size: 1.5rem !important;
  }

  h3 {
    margin-top: -1rem;
    padding-top: 1rem;
  }

  .nav-container.show {
    opacity: 1;
    visibility: visible;
    transition: 0.2s opacity cubic-bezier(0.75, 0, 1, 1) 0s, 0s visibility ease 0s;
    transition: 0.2s opacity var(--ease-in-transition) 0s, 0s visibility ease 0s;
  }
}
