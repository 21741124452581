.ui.grid.ReportHeaderDetailsTable {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ui.grid.ReportHeaderDetailsTable .row {
  padding: 5px 0;
}

.ReportHeaderSection .ReportDescription {
  font-size: 1.1rem;
}

/* PDF report overrides */

.ReportPDFPage .ui.grid.ReportHeaderDetailsTable {
  margin-bottom: 0px;
}
