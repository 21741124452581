
.AssetClassAllocationReportSection h2.ui.header {
    margin-top: 50px;
    break-after: avoid-page;
}

.AssetClassAllocationReportSection .chart-section {
    break-inside: avoid-page;
}

.AssetClassAllocationReportSection {
    margin-bottom: 60px;
}
