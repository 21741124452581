.SectionWrap {
  padding: 0 0 67px 0;
  background-color: white;
}

.SectionWrap.padding-top {
  padding-top: 1px;
}

.SectionWrap.huge-padding-top {
  padding-top: 82px;
}

.SectionWrap.toned {
  background-color: var(--bg-color);
}

.SectionWrap.toned.darker {
  background-color: var(--bg-color-darker);
}

.SectionWrap h2.header {
  margin-bottom: 31px;
}
