.content-wrapper {
  overflow: auto;
}

.recaptcha-wrapper {
  width: 300px;
  margin-top: 18px;
}

.form-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  margin-bottom: 10px;
  margin-top: 65px;
}

.form-wrapper form {
  margin-top: 50px;
}

.already-registered {
  margin: 30px 0 10px 0;
  max-width: 400px;
  font-size: small;
}

.robot-label {
  margin-bottom: 8px;
}
