@media all {
  .footer {
    --tw-bg-opacity: 1;
    background-color: rgba(8, 21, 25, var(--tw-bg-opacity));
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(248, 247, 239, var(--tw-text-opacity));
  }
  .footer a {
    color: white !important;
  }
  .footer a:not(.footer-social) {
    margin-bottom: 0.25rem;
    display: inline-flex;
  }
  .footer a:not(.footer-social)[target="_blank"]:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTQuMzE4MiAyVjMuODE4MThIMTkuMzc1TDguMjEwMjMgMTQuOTgzTDkuNTE3MDUgMTYuMjg5OEwyMC42ODE4IDUuMTI1VjEwLjE4MThIMjIuNVYySDE0LjMxODJaTTIuNSA1LjYzNjM2VjIySDE4Ljg2MzZWMTAuMTgxOEwxNy4wNDU1IDEyVjIwLjE4MThINC4zMTgxOFY3LjQ1NDU1SDEyLjVMMTQuMzE4MiA1LjYzNjM2SDIuNVoiIGZpbGw9IiNGOEY3RUYiLz4KPC9zdmc+);
    position: relative;
    margin-left: 0.5rem;
    top: 0.05em;
    height: 1.5rem;
  }
  .footer-social {
    border-radius: 9999px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
  }
  .footer-social:hover {
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  @media (min-width: 768px) {
    .footer .grid-lines {
      top: 0;
      bottom: 0;
    }
    .footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  @media (min-width: 976px) {
    .footer {
      padding-top: 3rem;
      padding-bottom: 3rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  @media (min-width: 1440px) {
    .footer {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
}
