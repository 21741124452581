.RiskProfileButtons {
  padding: 120px 0;
}

.RiskProfileButtons .button-row .ui.button {
  background-color: #fff;
  color: var(--primary-color);
  padding: 50px 15px;
  font-size: 70px;
  font-weight: normal;
}

.RiskProfileButtons .button-row .ui.button > span {
  font-weight: normal;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  line-height: 1.5;
}

.RiskProfileButtons .button-row .ui.button.active {
  background: var(--primary-color);
  border-bottom: 5px solid var(--primary-color);
  color: #fff;
  transition: background-color 0.25s ease-in-out;
  padding: 50px 10px 45px; /* remove 5 px from bottom due to border */
}

.RiskProfileButtons .button-row .ui.button.active:hover {
  background: var(--primary-hov-color);
  border-bottom: 5px solid var(--primary-color);
}

.RiskProfileButtons .button-row .ui.button:hover,
.RiskProfileButtons .button-row .ui.button:active {
  background-color: #f9f9f9;
}

.RiskProfileButtons .explanation {
  margin-bottom: 70px;
}

.RiskProfileButtons .ui.grid.buttons-grid > .row {
  padding-bottom: 0;
}

.RiskProfileButtons .risk-gradient {
  background: rgb(72, 175, 213);
  background: linear-gradient(90deg, var(--carnegie-light-orange) 0%, var(--carnegie-orange) 50%, var(--carnegie-red) 100%);
  height: 10px;
  margin-top: 10px;
}

.RiskProfileButtons .horizonlabel {
  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .RiskProfileButtons .button-row .ui.button {
    font-size: 40px;
  }
}
