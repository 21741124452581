.WelcomePage {
  background: var(--bg-color);
}

.WelcomePage .top-message {
  margin: 0 0 82px 0;
}

.WelcomePage .top-message > p {
  font-size: 20px;
}

.WelcomePage .cards > .step.card {
  border-radius: 0;
  background-color: #fff;
  box-shadow: none;
}

.WelcomePage .cards > .ui.card.step > .content {
  padding: 40px;
}

.WelcomePage .cards > .ui.card.step > .content > .header.number h1 {
  margin-top: 0;
  font-size: 70px;
  font-weight: normal;
  color: var(--primary-color);
  line-height: 1;
}

.WelcomePage .cards > .ui.card.step > .content > .header {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bolder;
  color: var(--primary-color);
  letter-spacing: -0.025em;
}

.WelcomePage .cards > .ui.card.step > .content > .description {
  margin-top: 30px;
  font-size: 1.1rem;
  color: #000;
}

.WelcomePage .got-it-button {
  margin-top: 80px;
}

.WelcomePage .main {
  /* padding-top: 120px; */
  padding-top: 80px;
  padding-bottom: 105px;
}
