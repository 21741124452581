.DisclaimerFooter {
    font-size: 15px;
    color: #b6bac2;
    margin: 3em 0 4em;
}

.DisclaimerFooter a {
    text-transform: unset !important;
    color: #b6bac2;
    font-weight: 400 !important;
}

.DisclaimerFooter a:hover {
    text-decoration: underline;
    color: #b6bac2;
}
