:root {
  --primary-color: #081519; /*old 448fa8*/
  --primary-hov-color: #081519; /*old 6cb1c9*/
  --secondary-color: #366d9d;
  --secondary-hov-color: #366d9d;
  --font-color: #4e4e4e;
  --dark-font-color: #2d2d2d;
  --link-color: #081519;
  --bg-color: #f3f1e7; /*old e9f3f7*/
  --bg-color-darker: #cee6ee;
  --carnegie-red: #c61e1e;
  --carnegie-light-red: #eba5a5;
  --carnegie-orange: #e6ac40;
  --carnegie-light-orange: #f7e5c5;
  --white-hov-color: #d5d5d5;

  /*new theme colors*/
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--nordic-black: #0b1519;
  --wp--preset--color--primary: #9a82ff;
  --wp--preset--color--cream: #f8f7ef;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
  --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
  --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
  --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
  --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
  --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
  --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
  --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
  --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--font-family--good-sans: Good Sans, sans-serif;
  --wp--preset--font-family--filmotype-yale: FilmotypeYale, serif;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;

  --ease-out-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-in-transition: cubic-bezier(0.75, 0, 1, 1);
  --ease-in-out-transition: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-transition: cubic-bezier(0.44, 0.21, 0, 1);
  --heavy-transition: cubic-bezier(0.36, 0.43, 0.99, 0.1);
}

/* OLD STYLES START */

*,
*:after,
*:before {
  box-sizing: border-box;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*:focus {
  outline: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
  font-family: "Good Sans", Arial, sans-serif;
  color: var(--dark-font-color);
}

h1,
h2,
h3,
h4,
h5,
.ui .button,
.SectionWrap .ui.header {
  font-family: "Good Sans", Arial, sans-serif;
}

.SectionWrap .DoubleSuggesterPortfolioSelector h2 {
  color: var(--font-color);
}

body.ios11-modal-scroll-bug-fix {
  position: fixed !important;
}

main {
  flex: auto;
  position: relative;
  background-color: var(--bg-color);
}

p.inverted {
  color: #fff;
}

h1.ui.header-first-child,
h2.ui.header-first-child,
h3.ui.header-first-child {
  margin-top: -0.14285em;
}

::-moz-selection {
  color: white;
  background: #005151;
}

::selection {
  color: white;
  background: #005151;
}

input[type="range"] {
  background-color: transparent;
  padding: 2px 0 !important;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: var(--primary-color);
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  background: #ffffff;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--primary-color);
}
input[type="range"]::-moz-range-track {
  background: var(--primary-color);
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  background: #ffffff;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: var(--primary-color);
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: var(--primary-color);
  border: 0.2px solid var(--secondary-color);
}
input[type="range"]::-ms-fill-upper {
  background: var(--primary-color);
  border: 0.2px solid var(--secondary-color);
}
input[type="range"]::-ms-thumb {
  background: #ffffff;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  margin-top: 0px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}
@supports (-ms-ime-align: auto) {
  input[type="range"] {
    margin: 0;
  }
}

.SectionWrap .chart-section h3,
.SectionWrap .ScaleFactorContributionSlider {
  margin-bottom: 10px;
}

.SectionWrap.toned .PortfolioContentChart,
.SectionWrap.toned .ForwardSimulationGraph,
.SectionWrap.toned .JoinedForwardSimulationGraph,
.SectionWrap.toned .ExpectedProfitAndWorstOutcomeReportChart,
.SectionWrap.toned .RiskReturnScatterplotChart,
.SectionWrap.toned .HistoricReturnGraph,
.SectionWrap.toned .DrawdownChart,
.SectionWrap.toned .RiskAllocationChart {
  background-color: white;
}

.SectionWrap .PortfolioContentChart,
.SectionWrap .ForwardSimulationGraph,
.SectionWrap .JoinedForwardSimulationGraph,
.SectionWrap .ExpectedProfitAndWorstOutcomeReportChart,
.SectionWrap .RiskReturnScatterplotChart,
.SectionWrap .HistoricReturnGraph,
.SectionWrap .DrawdownChart,
.SectionWrap .RiskAllocationChart {
  padding: 20px;
}

.SectionWrap .RiskReturnScatterplotChart,
.SectionWrap .HistoricReturnGraph,
.SectionWrap .DrawdownChart {
  margin-top: 60px;
}

.ReportPage .PortfolioContentChart,
.ReportPage .ForwardSimulationGraph,
.ReportPage .JoinedForwardSimulationGraph,
.ReportPage .ExpectedProfitAndWorstOutcomeReportChart,
.ReportPage .RiskReturnScatterplotChart,
.ReportPage .HistoricReturnGraph,
.ReportPage .DrawdownChart,
.ReportPage .RiskAllocationChart {
  margin-top: 30px;
}

.SectionWrap p,
.SectionWrap span {
  font-family: "Good Sans", Arial, sans-serif;
  font-size: 18px;
}
.graph-label-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.graph-label {
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}
.graph-label.active{
  background-color: var(--primary-color);
  color: white;
}

.expected-profil-header {
  padding: 15px 0 10px 0;
}

.AssetClassAllocationReportSection .ui,
.SectionWrap .ReportHeaderDetailsTable .row {
  font-family: "Good Sans", Arial, sans-serif;
}

.SectionWrap .carnegie__title {
  font-size: 32px;
  padding: 10px 0 20px 0;
}

.ui.total-sum.statistic > .value,
.ui.total-sum.statistic > .label {
  font-family: "Good Sans", Arial, sans-serif;
}

.ui.total-sum.orange.statistic > .value {
  color: var(--carnegie-red);
}
.ui.total-sum.blue.statistic > .value {
  color: var(--primary-color);
}

.ui.modal .label.carnegie__font,
.SectionWrap .ui.header {
  color: var(--font-color);
}

.SectionWrap .form .fields .carnegie__input label,
.ui.modal .form .carnegie__input label,
.SectionWrap .form .carnegie__input label {
  padding-bottom: 11px;
  display: block;
  font-size: 12px;
  color: var(--primary-color);
  font-weight: 600;
  font-family: "Good Sans", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.17px;
  margin: 0;
}

.ui.modal .form .carnegie__inline__input label,
.ui.modal .form .carnegie__inline__input span {
  font-size: 12px;
  color: var(--primary-color);
  font-family: "Good Sans", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.17px;
}

.ui.modal .form .carnegie__inline__input input,
.SectionWrap .form .carnegie__input input,
.ui.modal .form .carnegie__input input,
.ui.modal .form .carnegie__inline__input input,
.SectionWrap .form .fields .carnegie__input input,
.SectionWrap .form .carnegie__input textarea,
.SectionWrap .form .fields .carnegie__input textarea,
.SectionWrap .form .carnegie__input input:focus,
.SectionWrap .form .carnegie__input textarea:focus,
.SectionWrap .form .fields .carnegie__input input:focus,
.SectionWrap .form .fields .carnegie__input textarea:focus {
  width: 100%;
  padding: 15px 17px 13px;
  display: block;
  color: var(--dark-font-color);
  font-family: "Good Sans", Arial, sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 0;
  background: white;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

.SectionWrap .form .carnegie__input input:focus,
.ui.modal .form .carnegie__input input:focus,
.ui.modal .form .carnegie__inline__input input:focus,
.SectionWrap .form .carnegie__input textarea:focus,
.SectionWrap .form .fields .carnegie__input input:focus,
.SectionWrap .form .fields .carnegie__input textarea:focus {
  border-color: var(--secondary-color);
}

.SectionWrap .ui.button.carnegie__button,
.SectionWrap .ui.button.carnegie__button__secondary,
.ui.modal .carnegie__button,
.ui.modal .carnegie__button__secondary,
.SectionWrap .FundsTable .ui.button.carnegie__button,
.ProcessNavigator .ui.button.carnegie__button {
  display: inline-block;
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Good Sans, Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 1.3;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  padding: 14px 25px;
  border-radius: 24px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 767px) {
  .SectionWrap .ui.button.carnegie__button,
  .SectionWrap .ui.button.carnegie__button__secondary,
  .ui.modal .carnegie__button,
  .ui.modal .carnegie__button__secondary,
  .SectionWrap .FundsTable .ui.button.carnegie__button {
    margin-top: 20px;
  }
  .SectionWrap .form .fields .carnegie__input label,
  .ui.modal .form .carnegie__input label,
  .SectionWrap .form .carnegie__input label {
    margin-top: 10px;
  }
}

.SectionWrap .ui.button.carnegie__button,
.SectionWrap .ui.button.carnegie__button__secondary,
.ui.modal .carnegie__button,
.ui.modal .carnegie__button__secondary {
  font-size: 16px;
}

.SectionWrap .FundsTable .ui.button.carnegie__button,
.SectionWrap .FundsTable .ui.button.carnegie__button__secondary,
.ProcessNavigator .ui.button.carnegie__button {
  font-size: 13px;
}

.SectionWrap .ui.button.carnegie__button,
.ui.modal .carnegie__button,
.ProcessNavigator .ui.button.carnegie__button {
  background: var(--primary-color);
}

.ui.modal .carnegie__button__secondary,
.SectionWrap .FundsTable table .ui.button.carnegie__button__secondary {
  background-color: #f9fafb;
  color: var(--font-color);
}

.SectionWrap .MySavedSimulationsPage .ui.button.carnegie__button__secondary,
.SectionWrap .FundsTable .ui.button.carnegie__button__secondary {
  background-color: white;
  color: var(--font-color);
}

.SectionWrap .ui.button.carnegie__button__secondary {
  background: var(--secondary-color);
}
.SectionWrap .carnegie__button:hover:enabled,
.ui.modal .carnegie__button:hover:enabled,
.SectionWrap .carnegie__button__secondary:hover:enabled,
.ProcessNavigator .ui.button.carnegie__button:hover {
  color: white !important;
  text-decoration: none;
}

.ui.modal .carnegie__button__secondary:hover:enabled,
.SectionWrap .MySavedSimulationsPage .ui.button.carnegie__button__secondary:hover,
.SectionWrap .FundsTable .ui.button.carnegie__button__secondary:hover {
  background: var(--white-hov-color);
  color: var(--font-color) !important;
}

.ui.modal > .header {
  color: var(--font-color);
}

.ui.modal > .content {
  background-color: white;
}

.SectionWrap .carnegie__button:hover:enabled,
.SectionWrap .MySavedSimulationsPage .ui.button.carnegie__button:hover,
.ui.modal .carnegie__button:hover:enabled,
.ProcessNavigator .ui.button.carnegie__button:hover,
.ReportHeaderSection .DownloadButton.carnegie__button:hover {
  background: var(--primary-hov-color);
  color: white;
}

.SectionWrap .carnegie__button__secondary:hover:enabled {
  background: var(--secondary-hov-color);
}

.SectionWrap .carnegie__button:disabled,
.SectionWrap .carnegie__button[disabled],
.ui.modal .carnegie__button__secondary:disabled,
.SectionWrap .carnegie__button:hover:disabled,
.SectionWrap .carnegie__button__secondary:disabled,
.ui.modal .carnegie__button:disabled,
.ProcessNavigator .button.carnegie__button:disabled,
.ProcessNavigator .button.carnegie__button[disabled],
.ProcessNavigator .button.carnegie__button:hover:disabled {
  background-color: #cccccc;
  color: #666666;
}

@media all {
  main a {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  main a {
    color: var(--link-color);
    text-decoration: none;
  }
  main a:hover {
    text-decoration: underline;
  }
  main a {
    font-size: 18px;
    font-family: "Good Sans", Arial, sans-serif;
  }
  main a:not(.button) {
    color: #081519;
    font-weight: bold;
    text-decoration: none;
  }
  main a:not(.button):hover {
    text-decoration: underline;
  }
  main a:not(.button)[href^="mailto:"] {
    word-break: break-word;
  }
}

@media (max-width: 768px) {
  main a,
  .SectionWrap p {
    font-size: 16px;
  }
}

/* OLD STYLES END */

/*! CSS Used from: https://unpkg.com/swiper/swiper-bundle.min.css */
:root {
  --swiper-theme-color: #007aff;
}
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-horizontal {
  touch-action: pan-y;
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
:root {
  --swiper-navigation-size: 44px;
}
.swiper-scrollbar {
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  position: relative;
  background: var(--swiper-scrollbar-bg-color, rgba(0, 0, 0, 0.1));
}
.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  position: absolute;
  left: var(--swiper-scrollbar-sides-offset, 1%);
  bottom: var(--swiper-scrollbar-bottom, 4px);
  top: var(--swiper-scrollbar-top, auto);
  z-index: 50;
  height: var(--swiper-scrollbar-size, 4px);
  width: calc(100% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--swiper-scrollbar-drag-bg-color, rgba(0, 0, 0, 0.5));
  border-radius: var(--swiper-scrollbar-border-radius, 10px);
  left: 0;
  top: 0;
}
/*! CSS Used from: https://www.carnegiefonder.se/wp-content/litespeed/css/69173cf74801e35f4b3958373eff3b5f.css?ver=f3b5f ; media=all */
@media all {
  .wp-block-button__link {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    word-break: break-word;
    box-sizing: border-box;
  }
  :where(.wp-block-button__link) {
    box-shadow: none;
    text-decoration: none;
    border-radius: 9999px;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
  }
  .wp-block-buttons > .wp-block-button {
    display: inline-block;
    margin: 0;
  }
  .wp-block-buttons.is-content-justification-center {
    justify-content: center;
  }
  .wp-block-cover {
    position: relative;
    background-position: 50%;
    min-height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
  }
  .wp-block-cover .has-background-dim:not([class*="-background-color"]) {
    background-color: #000;
  }
  .wp-block-cover .wp-block-cover__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.5;
  }
  .wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-30 {
    opacity: 0.3;
  }
  .wp-block-cover:after {
    display: block;
    content: "";
    font-size: 0;
    min-height: inherit;
  }
  .wp-block-cover .wp-block-cover__inner-container {
    width: 100%;
    z-index: 1;
    color: #fff;
  }
  .wp-block-cover.is-light .wp-block-cover__inner-container {
    color: #000;
  }
  .wp-block-cover .wp-block-cover__image-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
    outline: none;
    border: none;
    box-shadow: none;
  }
  .wp-block-cover__image-background {
    z-index: 0;
  }
  ul {
    box-sizing: border-box;
  }
  .wp-element-button {
    cursor: pointer;
  }
  :root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px;
  }
  .has-text-align-center {
    text-align: center;
  }
  html :where(img[class*="wp-image-"]) {
    height: auto;
    max-width: 100%;
  }
  figure {
    margin: 0 0 1em;
  }
}
/*! CSS Used from: Embedded */
h2,
h3,
h6 {
  --wp--preset--font-size--jumbo: clamp(2.25rem, -0.1364rem + 7.6364vw, 7.5rem);
  --wp--preset--font-size--h-1: clamp(2.25rem, 1.5682rem + 2.1818vw, 3.75rem);
  --wp--preset--font-size--h-2: clamp(2rem, 1.5455rem + 1.4545vw, 3rem);
  --wp--preset--font-size--h-3: clamp(1.5rem, 1.3125rem + 0.625vw, 1.875rem);
  --wp--preset--font-size--h-4: clamp(1rem, 0.875rem + 0.4167vw, 1.25rem);
}
p {
  --wp--preset--font-size--large: clamp(1.125rem, 0.9375rem + 0.5vw, 1.375rem);
  --wp--preset--font-size--default: clamp(1rem, 0.9063rem + 0.25vw, 1.125rem);
  --wp--preset--font-size--small: 1rem;
}
body {
  margin: 0;
  --wp--style--global--content-size: 1248px;
  --wp--style--global--wide-size: 1248px;
}
:where(.is-layout-flex) {
  gap: 0.5em;
}
body .is-layout-flex {
  display: flex;
}
body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}
body .is-layout-flex > * {
  margin: 0;
}
body {
  font-family: var(--wp--preset--font-family--good-sans);
  font-size: clamp(1rem, 0.9063rem + 0.25vw, 1.125rem);
  line-height: 1.5;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
a:where(:not(.wp-element-button)) {
  text-decoration: underline;
}
h2 {
  font-size: clamp(2.25rem, 1.875rem + 1.25vw, 3rem);
  line-height: clamp(3rem, 2.625rem + 1.25vw, 3.75rem);
}
h3 {
  font-size: clamp(1.5rem, 1.3125rem + 0.625vw, 1.875rem);
  line-height: clamp(2rem, 1.8125rem + 0.625vw, 2.375rem);
}
.wp-element-button,
.wp-block-button__link {
  background-color: #32373c;
  border-width: 0;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
  text-decoration: none;
}
.has-cream-color {
  color: var(--wp--preset--color--cream) !important;
}
.ResetPasswordPage .has-cream-color {
  color: #000 !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
h2.has-h-1-font-size {
  font-size: var(--wp--preset--font-size--h-1) !important;
}
p.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
/*! CSS Used from: https://www.carnegiefonder.se/wp-content/litespeed/css/a352fa331df33d4a21ea8d4d9bb6b080.css?ver=6b080 ; media=all */
@media all {
  *,
  :before,
  :after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
  }
  :before,
  :after {
    --tw-content: "";
  }
  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Good Sans, sans-serif;
  }
  body {
    margin: 0;
    line-height: inherit;
  }
  h2,
  h3,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  strong {
    font-weight: bolder;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }
  button,
  input,
  select {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"] {
    appearance: button;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }
  h2,
  h3,
  h6,
  figure,
  p {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  input::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  button,
  [role="button"] {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }
  img,
  svg {
    display: block;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .z-50 {
    z-index: 50;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-12 {
    margin-bottom: 3rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mb-16 {
    margin-bottom: 4rem;
  }
  .flex {
    display: flex;
  }
  .grid {
    display: grid;
  }
  .hidden {
    display: none;
  }
  .h-full {
    height: 100%;
  }
  .h-\[40px\] {
    height: 40px;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .min-h-full {
    min-height: 100%;
  }
  .w-1\/3 {
    width: 33.333333%;
  }
  .w-full {
    width: 100%;
  }
  .w-1\/6 {
    width: 16.666667%;
  }
  .w-\[17px\] {
    width: 17px;
  }
  .flex-1 {
    flex: 1 1 0%;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-end {
    align-items: flex-end;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .gap-4 {
    gap: 1rem;
  }
  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .bg-nordic-black {
    --tw-bg-opacity: 1;
    background-color: rgba(8, 21, 25, var(--tw-bg-opacity));
  }
  .p-4 {
    padding: 1rem;
  }
  .py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pt-16 {
    padding-top: 4rem;
  }
  .pt-12 {
    padding-top: 3rem;
  }
  .pl-4 {
    padding-left: 1rem;
  }
  .pr-4 {
    padding-right: 1rem;
  }
  .text-center {
    text-align: center;
  }
  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .font-bold {
    font-weight: 700;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .text-cream-grid {
    --tw-text-opacity: 1;
    color: rgba(222, 221, 212, var(--tw-text-opacity));
  }
  .text-black-grid {
    --tw-text-opacity: 1;
    color: rgba(54, 61, 63, var(--tw-text-opacity));
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .text-cream {
    --tw-text-opacity: 1;
    color: rgba(248, 247, 239, var(--tw-text-opacity));
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html {
    position: relative;
    height: 100%;
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
    font-family: Good Sans, sans-serif;
    line-height: 1.625;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
  }
  #app {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .main {
    position: relative;
    flex: 1 0 auto;
  }
  figure,
  label,
  p,
  table,
  td,
  th,
  ul {
    margin: 0;
  }
  img,
  svg {
    max-width: 100%;
    height: auto;
  }
  button {
    display: inline-block;
    margin: 0;
    padding: 0;
    outline: 0;
    background: 0 0;
    line-height: 1;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }
  nav ul {
    list-style: none;
    margin-bottom: 0;
  }
  figure {
    margin: 0;
  }
  h2,
  h3,
  h6 {
    -webkit-font-smoothing: auto;
  }
  h2,
  h3,
  .h5 {
    font-weight: 700;
    text-transform: uppercase;
  }
  h2:not(:last-child),
  .h5:not(:last-child) {
    margin-bottom: 1rem;
  }
  .has-h-1-font-size {
    font-size: clamp(2.25rem, 1.5682rem + 2.1818vw, 3.75rem);
    line-height: clamp(2.8125rem, 2.1023rem + 2.2727vw, 4.375rem);
  }
  h2 {
    font-size: clamp(2rem, 1.5455rem + 1.4545vw, 3rem);
    line-height: clamp(2.5rem, 1.9318rem + 1.8182vw, 3.75rem);
  }
  h3 {
    font-size: clamp(1.5rem, 1.3125rem + 0.625vw, 1.875rem);
    line-height: clamp(2rem, 1.8125rem + 0.625vw, 2.375rem);
  }
  .has-jumbo-font-size {
    font-size: clamp(2.25rem, -0.1364rem + 7.6364vw, 7.5rem);
    line-height: clamp(2.8125rem, 0.1136rem + 8.6364vw, 8.75rem);
  }
  .text-large {
    font-size: clamp(1.25rem, 1.125rem + 0.4167vw, 1.5rem);
    line-height: 1.33;
  }
  .subtitle {
    font-size: 0.875rem;
    line-height: 1.4;
    letter-spacing: 0.02em;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s cubic-bezier(0.75, 0, 1, 1), opacity 0.2s cubic-bezier(0.75, 0, 1, 1);
    transition: color 0.2s var(--ease-in-transition), opacity 0.2s var(--ease-in-transition);
  }
  strong {
    font-weight: 700;
  }
  .entry-title {
    word-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
  }
  .col {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .col-small {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .col-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .col-full {
    width: 100%;
  }
  .col-third {
    width: 100%;
  }
  .grid-columns-2 .col:first-child {
    margin-bottom: 2rem;
  }
  .grid-lines {
    pointer-events: none;
    position: absolute;
    top: -1.5rem;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .line {
    height: 100%;
    width: 1px;
    background-color: currentcolor;
  }
  .header {
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 1rem;
  }
  .header-link {
    position: relative;
    z-index: 50;
    margin-top: -2px;
    display: block;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
  }
  .top-bar {
    top: 0;
    left: 0;
  }
  .logo {
    display: flex;
    width: 66.666667%;
    justify-content: center;
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.2s var(--ease-out-transition);
  }
  .nav {
    margin: 0;
  }
  .nav-logo {
    position: absolute;
    top: -3rem;
    left: 50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .nav-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: auto;
    justify-content: center;
    align-items: flex-start;
    background-color: #081519;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s opacity cubic-bezier(0.75, 0, 1, 1) 0s, 0s visibility ease 0.2s;
    transition: 0.2s opacity var(--ease-in-transition) 0s, 0s visibility ease 0.2s;
  }
  .nav-container .grid-lines {
    top: -3rem;
  }
  .nav-primary .menu-item {
    padding-top: 0.5rem;
    margin-top: -0.5rem;
  }
  .nav-primary .menu-item:not(:last-child) {
    margin-bottom: 1rem;
  }
  .nav-primary .menu-item a {
    position: relative;
    font-weight: 700;
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgba(248, 247, 239, var(--tw-text-opacity));
    font-size: clamp(1.5rem, 1.3125rem + 0.625vw, 1.875rem);
    line-height: clamp(2rem, 1.8125rem + 0.625vw, 2.375rem);
    transition: color 0.2s cubic-bezier(0.75, 0, 1, 1);
    transition: color 0.2s var(--ease-in-transition);
  }
  .menu-trigger {
    position: relative;
    display: block;
    width: 2.5rem;
    height: 2rem;
    z-index: 9999;
    cursor: pointer;
  }
  .menu-trigger span {
    position: absolute;
    display: block;
    height: 3px;
    width: 2.5rem;
    background-color: currentcolor;
    transition: all 0.5s cubic-bezier(0.1, 0.9, 0, 1.1);
  }
  .menu-trigger span:nth-child(1) {
    top: 0;
    right: 0;
  }
  .menu-trigger span:nth-child(2),
  .menu-trigger span:nth-child(3) {
    top: 10px;
    right: 0;
    transform: rotate(0);
  }
  .menu-trigger span:nth-child(4) {
    top: 20px;
    left: 0;
  }

  .menu-trigger.active {
    color: #f8f7ef;
  }
  .menu-trigger.active span:nth-child(1),
  .menu-trigger.active span:nth-child(4) {
    width: 0;
  }
  .menu-trigger.active span:nth-child(2) {
    transform: rotate(135deg);
  }
  .menu-trigger.active span:nth-child(3) {
    transform: rotate(45deg);
  }

  .lang-link {
    padding-right: 0.75rem;
  }
  .section .section-block + .section-block {
    margin-top: 3rem;
  }
  .fund-progress {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .fund-progress-arrow.down {
    transform: rotate(90deg);
  }
  .fund-select {
    min-width: 7rem !important;
  }
  .fund-select .select-custom-options {
    bottom: 3.5rem;
    top: auto;
    max-height: 14rem;
  }
  .wp-block-button__link,
  .button-secondary,
  .button-tertiary {
    font-size: 1rem;
    background-color: transparent;
    line-height: 1.1875;
    transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-radius 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 0.15s var(--ease-in-out-transition), border-radius 0.25s var(--ease-in-out-transition),
      background-color 0.15s var(--ease-in-out-transition), border-color 0.15s var(--ease-in-out-transition);
  }
  .button-secondary > * {
    pointer-events: none;
  }
  .wp-block-button.is-style-primary .wp-block-button__link {
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .wp-block-button.is-style-primary .wp-block-button__link:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 247, 239, var(--tw-text-opacity));
  }
  .button-secondary {
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgba(8, 21, 25, var(--tw-text-opacity));
  }
  .button-secondary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .button-tertiary,
  .wp-block-button.is-style-tertiary .wp-block-button__link {
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .button-tertiary:active,
  .wp-block-button.is-style-tertiary .wp-block-button__link:active {
    --tw-border-opacity: 1;
    border-color: rgba(248, 247, 239, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
  }
  .button-tertiary,
  .wp-block-button.is-style-tertiary .wp-block-button__link {
    border-radius: 10rem;
  }
  .button-tertiary:not(:disabled):not(.disabled):not(:hover):not(:focus),
  .wp-block-button.is-style-tertiary .wp-block-button__link:not(:disabled):not(.disabled):not(:hover):not(:focus) {
    border-radius: 0;
  }
  .cf-dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 2;
    display: flex;
    display: none;
    flex-direction: column;
    gap: 1px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(222, 221, 212, var(--tw-bg-opacity));
  }
  .cf-dropdown-menu-item {
    display: block;
  }
  .cf-dropdown-item {
    display: block;
    min-width: 100%;
    white-space: nowrap;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .cf-dropdown-item:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
  }
  .cf-dropdown-item {
    transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), border-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: background-color 0.2s var(--ease-out-transition), border-color 0.2s var(--ease-out-transition);
  }
  a:focus,
  button:focus,
  select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .swiper {
    width: 100%;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .swiper-container {
    overflow: hidden;
  }
  .swiper-wrapper {
    padding-bottom: 4rem;
  }
  .swiper-horizontal > .swiper-scrollbar {
    bottom: 0.75rem;
    left: 50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: calc(100% - 10rem);
  }
  .swiper-slide {
    display: flex;
    height: auto;
    max-height: 28rem;
    min-height: 100vw;
    flex-direction: column;
    justify-content: space-between;
    --tw-bg-opacity: 1;
    background-color: rgba(8, 21, 25, var(--tw-bg-opacity));
    padding: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .swiper-button-disabled {
    opacity: 0.3;
  }
  .swiper-scrollbar-drag {
    --tw-bg-opacity: 1;
    background-color: rgba(8, 21, 25, var(--tw-bg-opacity));
  }
  .slider-button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 4;
    display: flex;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    justify-content: space-between;
    width: calc(100% - 4rem);
  }
  .slider-button {
    height: 2rem;
    width: 2rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgba(8, 21, 25, var(--tw-bg-opacity));
    padding: 0.25rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.2s var(--ease-out-transition);
  }
  .select-native,
  .select-custom {
    position: relative;
    width: 100%;
  }
  .select-custom {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  @media (hover: hover) {
    .select-custom {
      display: block;
    }
    .select-native:focus + .select-custom {
      display: none;
    }
  }
  .select-native:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .select {
    position: relative;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .select-wrapper {
    position: relative;
  }
  .select-native,
  .select-custom-trigger {
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), border-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: background-color 0.2s var(--ease-out-transition), border-color 0.2s var(--ease-out-transition);
  }
  .select-native {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M12 16L6 8L18 8L12 16Z%27 fill=%27black%27/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 0.9rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    line-height: 1.2;
  }
  .select-custom-trigger {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 1rem 2.5rem 1rem 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.2;
  }
  .select-custom-trigger:after {
    content: "▾";
    position: absolute;
    top: 0;
    line-height: 3rem;
    right: 1.25rem;
  }
  .select-custom-options {
    position: absolute;
    top: 3.5rem;
    left: 0;
    width: 100%;
    border: 1px solid;
    background-color: #fff;
    z-index: 1;
    display: none;
    max-height: 20rem;
    overflow: auto;
  }
  .select-custom-option {
    position: relative;
    padding: 1.125rem 1.5rem;
    font-size: 1rem;
    transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: background-color 0.2s var(--ease-out-transition);
  }
  .select-custom-option:hover {
    cursor: default;
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
  }
  .select-custom-option:not(:last-of-type):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
  }
  .wp-block-sektion {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
  }
  .is-style-cream {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 247, 239, var(--tw-bg-opacity));
  }
  .is-style-cream .line {
    --tw-text-opacity: 1;
    color: rgba(222, 221, 212, var(--tw-text-opacity));
  }
  .wp-block-bild {
    margin-left: -15.5px;
    margin-right: -15.5px;
    margin-bottom: 0;
  }
  .wp-block-bild {
    display: flex;
    align-items: flex-start;
  }
  .wp-block-bild img {
    height: 100%;
    width: 100%;
  }
  .wp-block-buttons {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .wp-block-cover {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .wp-block-cover p {
    max-width: 60ch;
    margin: auto;
  }
  .has-large-font-size {
    line-height: 1.4;
  }
  label {
    margin-bottom: 0.25rem;
    display: block;
  }
  .span-fullwidth {
    margin-left: -1rem;
  }
  .span-fullwidth {
    width: calc(100% + 2rem);
  }
  .focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  @media (min-width: 480px) {
    .nav-primary .menu-item:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .wp-block-spacer {
      height: 2vh;
    }
    h1 {
      font-size: 36px;
      line-height: 1.33;
    }
  }
  @media (min-width: 768px) {
    .body {
      letter-spacing: 0.4px;
    }
    .md\:mb-12 {
      margin-bottom: 3rem;
    }
    .md\:mt-12 {
      margin-top: 3rem;
    }
    .md\:block {
      display: block;
    }
    .md\:flex {
      display: flex;
    }
    .md\:hidden {
      display: none;
    }
    .md\:w-1\/2 {
      width: 50%;
    }
    .md\:w-1\/6 {
      width: 16.666667%;
    }
    .md\:p-8 {
      padding: 2rem;
    }
    .md\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .md\:pt-24 {
      padding-top: 6rem;
    }
    .md\:pt-20 {
      padding-top: 5rem;
    }
    .md\:pl-8 {
      padding-left: 2rem;
    }
    .md\:pr-8 {
      padding-right: 2rem;
    }
    .md\:opacity-0 {
      opacity: 0;
    }
    .span-fullwidth {
      width: calc(100% + 6rem);
    }
    .span-fullwidth {
      margin-left: -3rem;
    }
    .wp-block-buttons {
      margin-top: 1.5rem;
    }
    .slider-button-container {
      width: calc(((100% - 8rem) / 3) + 7rem);
    }
    .swiper-slide {
      min-height: 50vw;
      padding: 2.5rem;
    }
    .swiper-horizontal > .swiper-scrollbar {
      width: calc(((100% - 8rem) / 3) - 2rem);
    }
    .swiper {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .section .section-block + .section-block {
      margin-top: 4rem;
    }
    .nav-container .grid-lines {
      top: -4rem;
    }
    .grid-columns-2 .col {
      width: 50%;
    }
    .grid-columns-2 .col:first-child {
      margin-bottom: 0;
    }
    .col-third {
      width: 50%;
    }
    .col-header {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .wp-block-spacer {
      height: 4vh;
    }
    .SectionWrap .carnegie__button {
      margin-top: 24px;
    }
    .SectionWrap .choose-portfolio-btn.carnegie__button {
      margin-top: 0;
    }
    .SectionWrap .form .carnegie__input label,
    .SectionWrap .form .fields .carnegie__input label {
      font-size: 14px;
    }
    .grid-lines {
      top: -3rem;
      bottom: -3rem;
    }
  }
  @media (min-width: 976px) {
    .col {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .col-third {
      width: 33.333333%;
    }
    .header {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .lang-link:after {
      cursor: pointer;
      content: "";
      position: relative;
      left: 11px;
      bottom: 1px;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid currentcolor;
    }
    .section .section-block + .section-block {
      margin-top: 6rem;
    }
    .lg\:mb-0 {
      margin-bottom: 0;
    }
    .lg\:mb-8 {
      margin-bottom: 2rem;
    }
    .lg\:w-1\/3 {
      width: 33.333333%;
    }
    .lg\:px-12 {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    .lg\:py-24 {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
    .wp-block-cover {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 9rem;
      padding-bottom: 9rem;
    }
    .wp-block-bild {
      margin-left: -31.5px;
      margin-right: -31.5px;
    }
    .select-native,
    .select-custom {
      min-width: 12rem;
    }
    .swiper-slide {
      min-height: 28vw;
    }
  }
  @media (min-width: 1440px) {
    .header {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .xl\:p-24 {
      padding: 6rem;
    }
    .xl\:px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .span-fullwidth {
      width: calc(100% + 12rem);
      margin-left: -6rem;
    }
    .slider-button-container {
      width: calc(((100% - 12rem) / 3) + 7rem);
    }
    .swiper-horizontal > .swiper-scrollbar {
      width: calc(((100% - 12rem) / 3) - 2rem);
    }
    .swiper {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
