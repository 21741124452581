.ReportRiskReturnSection .risk-return-box {
  background: transparent;
}

.ReportRiskReturnSection .risk-return-part h3 {
  margin-top: 44px;
  font-size: 18px;
}

.ReportRiskReturnSection .risk-return-box.grid {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ReportRiskReturnSection .risk-return-box.ui.grid > .row {
  padding: 0;
}

.ReportRiskReturnSection .risk-return-box .label,
.ReportRiskReturnSection .risk-return-box .number {
  text-align: center;
  text-transform: uppercase;
}

.ReportRiskReturnSection .risk-return-box .number {
  font-size: 36px;
  font-weight: normal;
}

.ReportRiskReturnSection .risk-return-box .label {
  font-size: 13px;
}
