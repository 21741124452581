.DistributionSlider {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.DistributionSlider label {
  padding: 0 1em;
  line-height: 41px;
  font-size: 15px;
}

.DistributionSlider .ui.input input {
  padding-left: 0;
  padding-right: 0;
}
