
.FundAllocationReportSection h2.ui.header {
    margin-top: 50px;
    break-after: avoid-page;
}

.FundAllocationReportSection .chart-section {
    break-inside: avoid-page;
}

.FundAllocationReportSection {
    margin-bottom: 60px;
}
