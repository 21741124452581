/* -- Portfolio tab switcher -- */

.SectionWrap .PortfolioSwitcher .ui.menu.switcher-tabs {
  margin-bottom: 42px;
  border: none;
  box-shadow: none;
}

.PortfolioSwitcher .ui.menu.switcher-tabs {
  margin-top: 40px;
  min-height: 2.2857142857em;
  background: transparent;
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item {
  background-color: var(--bg-color);
  padding: 0.6428571429em 1.25em;
  color: var(--font-color);
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item:hover {
  color: white;
  background-color: var(--secondary-color);
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item:first-child {
  border-radius: 20px 0 0 20px !important;
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item:last-child {
  border-radius: 0 20px 20px 0 !important;
}

.toned .PortfolioSwitcher .ui.menu.switcher-tabs > .item {
  background-color: #fff;
}

.toned .PortfolioSwitcher .ui.menu.switcher-tabs > .item:hover {
  color: white;
  background-color: var(--secondary-color);
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item.active {
  background-color: var(--primary-color);
  font-weight: 500;
  color: #fff;
  border-radius: 0;
}

.PortfolioSwitcher .ui.menu.switcher-tabs > .item.active:hover {
  background-color: var(--primary-hov-color);
}

.PortfolioSwitcher .ui.menu.switcher-tabs .item::before {
  width: 0;
}

.PortfolioSwitcher .ui.menu.switcher-tabs .item {
  margin-right: 2px;
}
