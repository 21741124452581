.FundsTable h2.ui.header {
  margin-bottom: 30px;
}

.FundsTable .TableHeader > tr {
  background: #f4f2f0 !important;
}

.FundsTable .assetclass-header {
  margin-top: 1em;
  color: #393939;
}

.FundsTable .index-fund {
  color: #999;
  font-style: italic;
}

.button-group {
  float: right;
  padding-bottom: 30px;
}

.button-group > .ui.button:first-child {
  border-radius: 24px 0 0 24px !important;
}

.button-group > .ui.button {
  border-radius: 0 !important;
}

.button-group > .ui.button:last-child {
  border-radius: 0 24px 24px 0 !important;
}
