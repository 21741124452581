.TeaserPage .teaser-wrap {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 20px 50px;
}

.TeaserPage .continue-button .ui.button {
  float: right;
  margin: 40px 60px 60px 0;
}

.TeaserPage .continue-button-mobile {
  margin-top: 40px !important;
}

.TeaserPage .teaser-divider {
  margin: 60px 0 40px 0;
}

.TeaserPage .teaser-plot {
  margin: 0 auto;
  padding-bottom: 20px;
  max-width: 400px;
}

.TeaserPage .teaser-plot p {
  font-size: 14px;
}

.TeaserPage .charts-wrap {
  display: flex;
}

.TeaserPage .center {
  margin-left: auto;
  margin-right: auto;
}

.TeaserPageHeader {
  background: #f4f2f0;
  background-size: cover;
  padding: 27px 0 20px;
}

.TeaserPageHeader h1.ui.header {
  margin: 0;
}

.TeaserPage .main-section {
  padding-top: 60px;
}

/*! CSS Used from: https://www.carnegiefonder.se/app/themes/carnegiefonder/dist/css/index.css?ver=2c837ab2c7cadbdc35b5bd7115e9eff1 ; media=all */
@media all {
  a {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  *:focus {
    outline: 0;
  }
  a {
    color: #2d2d2d;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a {
    font-family: Good Sans, Arial, sans-serif;
  }
}
