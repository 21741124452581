/* -- Double suggester portfolio selector -- */
.DoubleSuggesterPortfolioSelector {
  overflow: hidden;
  padding-top: 1.64em;
  padding-bottom: 1.64em;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .DoubleSuggesterPortfolioSelector {
    padding-top: 5.25em;
    padding-bottom: 5.25em;
  }
}

.DoubleSuggesterPortfolioSelector .portfolio-b-header-row {
  margin-top: 30px;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers {
  display: flex;
  justify-content: center;
  color: #fff;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure {
  flex: 1;
  text-align: center;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure {
  margin-left: 5px;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure:first-child {
  margin-left: 0;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure > span {
  font-size: 36px;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  padding: 10px 0 0;
  color: var(--primary-color);
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure.secondary > span {
  color: var(--secondary-color);
  border-color: #005151;
}

.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure > span,
.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure > label {
  display: block;
}

.DoubleSuggesterPortfolioSelector .selector-description {
  display: block;
  margin-top: 10px;
}

.DoubleSuggesterPortfolioSelector .selector-description,
.DoubleSuggesterPortfolioSelector .risk-return-numbers > .figure > label {
  color: #393939;
  font-size: 13px;
  text-transform: uppercase;
}

.DoubleSuggesterPortfolioSelector .ui.choose-portfolio-btn {
  margin-bottom: 0.4em;
}
