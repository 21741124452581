/* -- Cross-browser range slider styling -- */

.ui.input input[type="range"] {
  background: transparent;
  border: none;
}

input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;

  /*required for proper track sizing in FF*/
  width: 130px;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-ms-track {
  width: 130px;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 130px;
  height: 5px;
  background: #e1dad8;
  border: none;
  border-radius: 3px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e1dad8;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: #e1dad8;
  border-radius: 10px;
}
input[type="range"]::-ms-thumb {
  border: 1px solid #000;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
  margin-top: -4px;
}
input[type="range"]::-moz-range-track {
  height: 5px;
  background: #e1dad8;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  border: 1px solid #000;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ffffff;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}
