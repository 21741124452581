@media print {
  * {
    overflow: visible !important;
  }
  @page {
    margin: 0;
  }
  .chartjs-size-monitor {
    position: fixed !important;
  }
  .page {
    page-break-after: always;
  }
  body {
    margin-left: 1.6cm;
    margin-right: 1.6cm;
  }
  .ui.text.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* headers */

.ReportPDFPage h1.ui.header {
  padding-top: 40px;
  border-bottom: 2px solid #ccc;
}

.ReportPDFPage h2.ui.header {
  margin-top: 30px;
  padding-top: 40px;
}

/* .report-header */

.ReportPDFPage .report-header {
  background: white;
  background-size: cover;
  color: white;
  padding: 0 0 25px 0;
  display: flex;
}

.ReportPDFPage .report-header .title {
  color: var(--primary-color);
  flex: 0 0 70%;
}

.ReportPDFPage .report-header .title h1 {
  margin: 65px 0 0 55px;
}

.ReportPDFPage .report-header .logo {
  flex: 1;
  margin-left: 20px;
  margin-top: 50px;
}

.ReportPDFPage .report-header .logo svg {
  width: 105px;
  height: 72px;
  justify-content: center;
  display: block;
  margin: auto;
}
.ReportPDFPage .report-header .logo svg * {
  fill: var(--primary-color);
}

.ReportPDFPage .report-header h1.ui.header {
  margin-top: 20px;
  padding-top: 0;
  border-bottom: none;
}

/* overrides */

.ReportPDFPage .ReportHeaderDetailsTable {
  font-family: "Good Sans", Arial, sans-serif;
}

/* disclaimer overrides */
.ReportPDFPage .pdf-disclaimer-footer {
  padding-top: 50px;
}

.ReportPDFPage .DisclaimerFooter {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 2px solid #ccc;
}

.ReportPDFPage .chart-section {
  padding: 5vh 0;
}
