@media screen and (max-width: 767px) {
  .LoginPage .forgot-link {
    margin: 14px 0 0 0;
  }
}

.LoginPage .ui.form .field.disabled .button.primary:disabled {
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.login-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.new-customer-wrap {
  text-align: center;
  margin: 60px auto 0 auto;
  max-width: 400px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .new-customer-wrap {
    font-size: 16px;
  }
}

.short {
  width: 30% !important;
}
